<template>
  <div class="unauthorized__layout">
    <router-view></router-view>
  </div>
</template>

<style scoped lang="scss">
.unauthorized__layout {
  width: 100vw;
  height: 100vh;
  background-color: #eef0f3;
  color: #3c4b64;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>