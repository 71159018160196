class StyleGenerator {
  private _value: Record<string, string> = {};
  constructor() { }

  private _parse(val: string | number) {
    return typeof val === 'string' ? val : val + 'px';
  }

  w(val: number | string) {
    this._value['width'] = this._parse(val);
    return this;
  }
  minw(val: number | string) {
    this._value['minWidth'] = this._parse(val);
    return this;
  }
  maxw(val: number | string) {
    this._value['maxWidth'] = this._parse(val);
    return this;
  }
  h(val: number | string) {
    this._value['height'] = this._parse(val);
    return this;
  }
  minh(val: number | string) {
    this._value['minHeight'] = this._parse(val);
    return this;
  }
  maxh(val: number | string) {
    this._value['maxHeight'] = this._parse(val);
    return this;
  }

  p(val: 'inherit' | 'absolute' | 'fixed' | 'relative' | 'sticky') {
    this._value['position'] = val;
    return this;
  }

  t(val: number | string) {
    this._value['top'] = this._parse(val);
    return this;
  }
  l(val: number | string) {
    this._value['left'] = this._parse(val);
    return this;
  }
  r(val: number | string) {
    this._value['right'] = this._parse(val);
    return this;
  }
  b(val: number | string) {
    this._value['bottom'] = this._parse(val);
    return this;
  }

  bg(val: string) {
    this._value['background-color'] = this._parse(val);
    return this;
  }

  /** font-size */
  f(val: number | string) {
    this._value['font-size'] = this._parse(val);
    return this;
  }

  scroll() {
    this._value['overflow'] = 'scroll';
    return this;
  }

  scrollX() {
    this._value['overflow-x'] = 'scroll';
    return this;
  }

  scrollY() {
    this._value['overflow-y'] = 'scroll';
    return this;
  }

  round(val: number | string) {
    this._value['border-radius'] = this._parse(val);
    return this;
  }

  zIndex(val: number) {
    this._value['z-index'] = `${val}`;
    return this;
  }

  headerZIndex() {
    this._value['z-index'] = '1051';
    return this;
  }
  alertZIndex() {
    this._value['z-index'] = '1049';
    return this;
  }

  border(val: string | [string, string] | [string, string, string, string]) {
    if (typeof val === 'string') {
      this._value['border'] = val;
    } else if (val.length === 2) {
      this._value['border-top'] = val[0];
      this._value['border-bottom'] = val[0];
      this._value['border-left'] = val[1];
      this._value['border-right'] = val[1];
    } else if (val.length === 4) {
      this._value['border-top'] = val[0];
      this._value['border-bottom'] = val[1];
      this._value['border-left'] = val[2];
      this._value['border-right'] = val[3];
    }

    return this;
  }

  pa(val: number | string) {
    this._value['padding'] = this._parse(val);
    return this;
  }
  ma(val: number | string) {
    this._value['margin'] = this._parse(val);
    return this;
  }

  center() {
    this._value['display'] = 'flex';
    this._value['align-items'] = 'center';
    this._value['justify-content'] = 'center';
    return this;
  }
  between() {
    this._value['display'] = 'flex';
    this._value['align-items'] = 'center';
    this._value['justify-content'] = 'space-between';
    return this;
  }
  start() {
    this._value['display'] = 'flex';
    this._value['align-items'] = 'center';
    this._value['justify-content'] = 'start';
    return this;
  }
  end() {
    this._value['display'] = 'flex';
    this._value['align-items'] = 'center';
    this._value['justify-content'] = 'end';
    return this;
  }

  v() {
    return this._value;
  }

  toString() {
    let style = '';
    const value = this.v();
    for (let key in value) {
      style += `${key}: ${value[key]};`;
    }
    return style;
  }
}


export const s = () => new StyleGenerator();